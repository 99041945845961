<template>
    <div class="inner-section">
        <card>
            <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{$t('elearning_iabm.course_document')}}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="mobile"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.mobile')}}
                        </template>
                        <b-form-input
                            id="mobile"
                            v-model="search.mobile"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_from"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_from')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.date_from"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_to"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_to')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.date_to"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_iabm.course_document_list')}}</h4>
            </template>
            <!-- <template v-slot:headerAction>
                <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                        {{  $t('globalTrans.add_new') }}
                </b-button>
            </template> -->
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                        <b-col sm="12">
                        <div class="quick-filter-wrapper">
                            <div class="quick-filter-left">
                            <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                                <b-dropdown-form>
                                <div
                                    class="form-group"
                                    v-for="(field, index) in labelData"
                                    :key="index"
                                    >
                                    <b-form-checkbox
                                        :id="'checkbox-' + field.label"
                                        v-model="field.show"
                                        :name="'checkbox-' + field.label"
                                        value=1
                                        unchecked-value=0
                                    >
                                        {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                    </b-form-checkbox>
                                </div>
                                </b-dropdown-form>
                            </b-dropdown>
                            </div>
                            <div class="quick-filter-right">
                            <b-form-group
                                :label="$t('menu.perpage')"
                                label-for="per-page-select"
                            >
                                <b-form-select
                                id="per-page-select"
                                v-model="search.limit"
                                :options="pageOptions"
                                size="sm"
                                ></b-form-select>
                            </b-form-group>
                            </div>
                        </div>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" class="table-responsive">
                        <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                          <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                            <template v-slot:cell(serial)="data">
                              {{ $n(data.item.serial + pagination.slOffset) }}
                            </template>
                            <template v-slot:cell(mobile)="data">
                                {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                            </template>
                            <template v-slot:cell(type_name)="data">
                            <span class="capitalize">{{ data.item.type_name }}</span>
                            </template>
                            <template v-slot:cell(date)="data">
                                <span class="capitalize">{{ data.item.date | dateFormat }}</span>
                            </template>
                            <template v-slot:cell(course_document)="data">
                                <a target = '_blank' v-if="data.item.course_document" :href="baseUrl + 'download-attachment?file=uploads/iabm-documents/' + data.item.course_document" title="Course Document" class="btn_table_action table_action_status" download><i class="ri-download-cloud-fill"></i></a>
                            </template>
                            <template v-slot:cell(status)="data">
                                <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                            </template>
                            <template v-slot:cell(action)="data">
                                <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="fa fa-upload"></i></a>
                                <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                <a href="javascript:" class="btn_table_action table_action_toggle" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                            </template>
                          </b-table>
                        </slot>
                        <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </div>
</template>
<script>

import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { requestDocumentList, requestDocumentToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            sortBy: '',
            sortDirection: '',
            sortDesc: '',
            search: {
                org_id: 0,
                mobile: '',
                date_from: '',
                date_to: '',
                limit: 20
            },
            baseUrl: trainingElearningServiceBaseUrl,
            labelData: [
                { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
                { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
                { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
                { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
                { labels: 'elearning_iabm.course_document', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
                { labels: 'globalTrans.reason', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
                { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
                { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
            ]
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_iabm.request_coursee_document') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.request_coursee_document') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labelData = this.labelData
            const labels = labelData.map((item, index) => {
                return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
            })
            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'serial' },
                    { key: 'mobile' },
                    { key: 'name' },
                    { key: 'date' },
                    { key: 'course_document' },
                    { key: 'reason_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'serial' },
                    { key: 'mobile' },
                    { key: 'name' },
                    { key: 'date' },
                    { key: 'course_document' },
                    { key: 'reason' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        pageOptions () {
            return this.$store.state.commonObj.pageOptions
        }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId
        })
        this.loadData()
    },
    mounted () {
        flatpickr('.fromDate', {})
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, requestDocumentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
        },
        async loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, requestDocumentList, params)
            if (result.success) {
                const listData = result.data.data.map((item, index) => {
                    return Object.assign({}, item, { serial: index })
                })
                this.$store.dispatch('setList', listData)
                this.paginationData(result.data, this.search.limit)
            } else {
                this.$store.dispatch('setList', [])
                this.paginationData([])
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
            Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
                if (response.success) {
                /** The following line commented as list is reloaded */
                // Store.dispatch('toggleStatus', item)
                if (destination !== null && dropdownName !== null) {
                    Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
                }
                window.vm.$toast.success({
                    title: 'Success',
                    message: 'Data Updated Successfully',
                    color: '#D6E09B'
                })
                } else {
                window.vm.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
                }
                Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
            })
        }
    }
}
</script>
